import React from "react";
import { Link } from "gatsby";

import StaticLayout from "../components/StaticLayout";
import imgSrc from "../images/Disco-swing.jpg";

const Page = () => (
  <StaticLayout isSingle>
    <main id="main" className="site-main" role="main">
      <div className="svg-container single-post-svg svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1890 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 1925,0 0,150 -1925,0" />
        </svg>
      </div>
      <article
        id="post-13"
        className="post-13 post type-post status-publish format-standard hentry category-geen-categorie"
      >
        <header className="entry-header">
          <h1 className="entry-title">Disco-Boogie (Swing)</h1>
          <div className="entry-meta" />
          {/* .entry-meta */}
        </header>
        {/* .entry-header */}
        <div className="entry-content">
          <p>
            <img
              className="size-medium wp-image-211 aligncenter"
              src={imgSrc}
              alt=""
              width={300}
              height={300}
            />
          </p>
          <p>
            De disco-boogie wordt ook wel eens de discoswing genoemd. Deze dans
            is ideaal voor paren die graag uit de bol gaan op pop-of
            discomuziek. Het is een mix van elementen uit verschillende dansen.
            Zo kan je de passen en de danshouding van de foxtrot terugvinden,
            maar evengoed technieken uit latindansen en de choreografie van de
            rock-‘n-roll. De disco-boogie is door het eenvoudige dansritme heel
            geschikt voor beginnende dansers. Ook heel belangrijk: deze dans is
            gemakkelijk te dansen op feestjes!
          </p>
          <p>
            <Link className="custom-button" to="/registratie-cursussen">
              Inschrijven voor deze lessenreeks
            </Link>
          </p>
        </div>
        {/* .entry-content */}
        <footer className="entry-footer" />
        {/* .entry-footer */}
      </article>
      {/* #post-## */}
      <div className="svg-container single-post-svg single-svg-bottom svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1920 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="M 0,150 0,0 1925,0" />
        </svg>
      </div>
      <nav className="navigation post-navigation" role="navigation">
        <h2 className="screen-reader-text">Berichtnavigatie</h2>
        <div className="nav-links">
          <div className="nav-previous">
            <Link to="/">Terug naar de startpagina</Link>
          </div>
        </div>
      </nav>
    </main>
  </StaticLayout>
);

export default Page;
