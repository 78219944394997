/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

type StaticLayoutProps = {
  children: React.ReactNode;
  isSingle?: boolean;
};

const StaticLayout = ({ children, isSingle = false }: StaticLayoutProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggle = () => {
    setIsOpen((value) => !value);
  };
  return (
    <main
      className={
        isSingle
          ? "post-template-default single single-post single-format-standard"
          : "home blog"
      }
    >
      <Helmet>
        <title>Dance Square – Never miss a chance to dance!</title>
        <link rel="stylesheet" href="/styles/main.css" />
      </Helmet>
      <div id="page" className="hfeed site">
        <a className="skip-link screen-reader-text" href="#content">
          Naar de inhoud springen
        </a>
        <div
          className={`sidebar-toggle${isOpen ? " sidebar-toggled" : ""}`}
          onClick={toggle}
        >
          <i className={`fa ${isOpen ? "fa-times" : "fa-bars"}`} />
        </div>
        <div className="top-bar container" />
        <div className="svg-container nav-svg svg-block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 1950 150"
          >
            <g transform="translate(0,-902.36218)" />
            <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
            <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
            <path d="M 0,150 0,0 1950,0" />
          </svg>
        </div>{" "}
        <header
          id="masthead"
          className="site-header static-website-header"
          role="banner"
          style={{ backgroundPosition: "50% 0px" }}
        >
          <div className="overlay" />
          <div className="container">
            <div className="site-branding">
              <h1 className="site-title">
                <Link to="/" rel="home">
                  Dance Square
                </Link>
              </h1>
              <h2 className="site-description">
                Never miss a chance to dance!
              </h2>
            </div>
            {/* .site-branding */}
          </div>
          <div className="svg-container header-svg svg-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1890 150"
            >
              <g transform="translate(0,-902.36218)" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 1925,0 0,150 -1925,0" />
            </svg>
          </div>
        </header>
        {/* #masthead */}
        <div id="content" className="site-content">
          <div className="container content-wrapper">
            <div id="primary" className="content-area">
              {children}
              {/* #main */}
            </div>
            {/* #primary */}
            <div
              id="secondary"
              className={`widget-area${isOpen ? " widget-area-visible" : ""}`}
              role="complementary"
            >
              <nav className="sidebar-nav">
                <div className="slicknav_menu">
                  <ul
                    className="slicknav_nav slicknav_hidden"
                    style={{ display: "none" }}
                    aria-hidden="true"
                    role="menu"
                  >
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-391">
                      <Link
                        to="/registratie-cursussen/"
                        role="menuitem"
                        tabIndex={-1}
                      >
                        Registratie cursussen
                      </Link>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-57">
                      <Link to="/dansaanbod/" role="menuitem" tabIndex={-1}>
                        Dansaanbod
                      </Link>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-52">
                      <Link to="/uurrooster/" role="menuitem" tabIndex={-1}>
                        Uurrooster
                      </Link>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-54">
                      <Link to="/lesgevers/" role="menuitem" tabIndex={-1}>
                        Lesgevers
                      </Link>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-55">
                      <Link to="/galerij/" role="menuitem" tabIndex={-1}>
                        Galerij
                      </Link>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-56">
                      <Link to="/danspartner/" role="menuitem" tabIndex={-1}>
                        Danspartner
                      </Link>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-53">
                      <Link to="/ligging/" role="menuitem" tabIndex={-1}>
                        Locaties
                      </Link>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-58">
                      <Link to="/22-2/" role="menuitem" tabIndex={-1}>
                        Contacteer ons
                      </Link>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-389">
                      <Link
                        to="/huishoudelijk-reglement/"
                        role="menuitem"
                        tabIndex={-1}
                      >
                        Huishoudelijk reglement
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            {/* #secondary */}
          </div>
        </div>
        {/* #content */}
        <div className="svg-container footer-svg svg-block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 1890 150"
          >
            <g transform="translate(0,-902.36218)" />
            <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
            <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
            <path d="m 1925,0 0,150 -1925,0" />
          </svg>
        </div>{" "}
        <footer id="colophon" className="site-footer" role="contentinfo">
          <div className="container">
            <div className="col-xs-12 col-md-6 site-info" />{" "}
            <nav
              id="footernav"
              className="footer-navigation col-md-6 col-xs-12"
              role="navigation"
            />
            {/* #site-navigation */}
          </div>
          {/* .site-info */}
        </footer>
      </div>
      {/* #colophon */}
      {/* #page */}
    </main>
  );
};

export default StaticLayout;
